import Axios from 'axios';
import { FirebaseUriHelper } from './helpers/firebase-uri-helper';

/**
 * This is a lighweight replacement of firebase.httpsCallable.call('functionName').
 * It enables you to call firebase functions in a convinient way without having to import
 * that riddicilously huge firebase-js-sdk
 */
class FirebaseCloudFunctions {
  constructor (private firebaseUriHelper: FirebaseUriHelper = new FirebaseUriHelper()) {}

  /**
   * Call a firebase cloud function
   * @param functionName the name of the cloud function you want to call
   * @param payload the Payload you want to send to the cloud function
   */
  call (functionName: string, payload: { [key: string ]: unknown }) {
    return Axios.post(this.firebaseUriHelper.getFunctionsUrl(functionName), { data: payload });
  }
}

const cloudFunctions = new FirebaseCloudFunctions();
export { cloudFunctions };
