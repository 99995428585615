import { IVueEnvVarHelper } from '@/interfaces';
import { VueEnvHelper } from './vue-envvar-helper';

/**
 * this class builds the functions url for live, testing, and emulated functions.
 */
export class FirebaseUriHelper {
  constructor (private envHelper: IVueEnvVarHelper = new VueEnvHelper('_FIREBASE')) {}

  /**
   * Retrieve Firebase functions base Url depending on app environment (testing | production)
   */
  get functionsBaseUrl () {
    return `https://${this.functionsRegion}-${this.projectId}.cloudfunctions.net`;
  }

  get functionsBaseUrlForCurrentEnvironment () {
    if (this.envHelper.get('EMULATOR_FUNCTIONS_ENABLED')) {
      return this.emulatedFunctionsBaseUrl;
    }
    return this.functionsBaseUrl;
  }

  /**
   * This builds the functions base url for the Emulator
   * @return {string} the functions base Urls
   */
  get emulatedFunctionsBaseUrl () {
    const host = `http://${this.envHelper.get('FUNCTIONS_HOST', 'localhost')}`;
    const port = `${this.envHelper.get('EMULATOR_FUNCTIONS_PORT', '5001')}`;
    return `${host}:${port}/${this.projectId}/${this.functionsRegion}`;
  }

  /**
   * retrieve functions region from env
   * @return {string} the region name
   */
  get functionsRegion () {
    return this.envHelper.get('FUNCTIONS_REGION');
  }

  /**
   * get firebase project id from env vars
   * @return {string} the project id
   */
  get projectId () {
    return this.envHelper.get('PROJECT_ID');
  }

  /**
   * Retrieve the complete url for a fucntion in the currently running env.
   * @param name the name of the function you want to call
   * @return {string} the function name
   */
  getFunctionsUrl (name: string) {
    return `${this.functionsBaseUrlForCurrentEnvironment}/${name}`;
  }
}

const firebaseUrlHelper = new FirebaseUriHelper();

export { firebaseUrlHelper };
