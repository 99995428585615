




import Component from 'vue-class-component';
import Vue from 'vue';
import { IVisitConditions } from '@einfachgast/shared';

@Component({
  name: 'VenueIntroText',
})
export default class VenueIntroText extends Vue {
  visitConditions: IVisitConditions = null;

  get introText () {
    if (typeof this.$visitConditionsStore.visitConditions?.introText === 'string') {
      return (this.$visitConditionsStore.visitConditions?.introText as string).replace(/\n\r?/g, '<br />');
    }
    const introText = (this.$visitConditionsStore.visitConditions?.introText[this.$i18n.locale])
      ? this.$visitConditionsStore.visitConditions?.introText[this.$i18n.locale]
      : this.$visitConditionsStore.visitConditions?.introText[this.$i18n.fallbackLocale.toString()];
    return introText?.replace(/\n\r?/g, '<br />');
  }
}
