import type { IPendingVisit, IReinitCheckinOptions, IVisitStore } from '@/interfaces';
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';
import sessionstorageHelper from '@/helpers/session-storage-helper';
import { cloudFunctions } from '../firebase-cloud-functions';
import localStorageHelper from '@/helpers/local-storage-helper';
import router, { RouteNames } from '@/router';
import { IVisit } from '@einfachgast/shared';
import { PendingVisitLoadingDenialReasons } from '@/enums/pending-visit-loading-denial-reasons';
import { VisitConditionsStoreModule } from './visit-conditions-store-module';
import { store } from '.';
import { CheckinDenialReasons } from '@/enums/checking-denial-reasons';
import { CheckInResultDisplayType } from '@/enums/checkin-result-display-types';
import { SentryService } from '@/helpers/sentry/sentry-service';

@Module({ namespaced: true, name: 'Visit' })
export class VisitStoreModule extends VuexModule implements IVisitStore {
  private _pendingVisit: IPendingVisit = null;
  private _loading = false;

  get loading () {
    return this._loading;
  }

  get checkoutToken () {
    return localStorageHelper.loadCheckoutToken();
  }

  @Mutation
  setLoading (loading: boolean) {
    this._loading = loading;
  }

  @Mutation
  setPendingVisit (pendingVisit: IPendingVisit) {
    this._pendingVisit = pendingVisit;
  }

  get pendingVisit () {
    return this._pendingVisit;
  }

  @Mutation
  setVisitConditions (pendingVisit: IPendingVisit) {
    this._pendingVisit = pendingVisit;
  }

  @Action
  async save (options: { visit: IVisit; asAdmin?: boolean }) {
    this.context.commit('setLoading', true);
    try {
      const response = await cloudFunctions.call(
        'storeVisit',
        { visitorData: options.visit.toFirestorageFormat(), token: sessionstorageHelper.loadVenueToken() },
      );

      if (response && !response.data.result.success) {
        if (response.data.result.reason === CheckinDenialReasons.LimitReached) {
          this.context.commit('setLoading', false);
          return {
            success: false,
            errorReason: response.data.result.reason,
            message: 'visitorLimitReachedError',
            displayDuration: 15000,
            displayType: CheckInResultDisplayType.Toast,
          };
        }
      }

      // Persistently Store Address data to fill form automatically the next time.
      localStorageHelper.saveVisitorData(options.visit);
      sessionstorageHelper.saveVisitorData(options.visit);

      this.context.commit('setLoading', false);

      if (options.asAdmin) {
        this.context.commit('setLoading', false);
        return {
          success: true,
          message: 'successRegisterGuest',
          translateData: {
            name: `${options.visit.firstname} ${options.visit.lastname}`,
          },
          displayType: CheckInResultDisplayType.Dialog,
        };
      }

      await router.replace({ name: 'Finished', params: { visit: JSON.stringify(options.visit) } });
    } catch (e) {
      this.context.commit('setLoading', false);
      return {
        success: false,
        message: 'errorWhileSaving',
        displayDuration: 6000,
        displayType: CheckInResultDisplayType.Toast,
      };
    }

    return {
      success: true,
    };
  }

  @Action
  async checkIn (options: { visit: IVisit; asAdmin?: boolean }) {
    this.context.commit('setLoading', true);
    let response : any;
    try {
      response = await cloudFunctions.call('checkin', { visitorData: options.visit.toFirestorageFormat(), token: sessionstorageHelper.loadVenueToken() });
      if (response && !response.data.result.success) {
        if (response.data.result.reason === CheckinDenialReasons.LimitReached) {
          this.context.commit('setLoading', false);
          return {
            success: false,
            displayType: CheckInResultDisplayType.Toast,
            errorReason: response.data.result.reason,
            message: 'visitorLimitReachedError',
            displayDuration: 15000,
          };
        }
      }

      if (options.asAdmin) {
        this.context.commit('setLoading', false);
        return {
          success: true,
          message: 'successRegisterGuestCheckIn',
          translateData: {
            name: `${options.visit.firstname} ${options.visit.lastname}`,
          },
          displayType: CheckInResultDisplayType.Dialog,
        };
      }

      localStorageHelper.saveCheckoutToken(response.data.result.token);
      localStorageHelper.saveVisitorData(options.visit);

      this.context.commit('setPendingVisit', response.data.result.data);
      await router.push({ name: RouteNames.CheckedIn });
    } catch (e) {

      void new SentryService().captureError({
        message: 'Error while checking in',
        extra: {
          error: e,
          asAdmin: options.asAdmin,
          functionsResponse: response,
          token: sessionstorageHelper.loadVenueToken(),
        },
      } as Error & { extra: { error: Error; asAdmin: boolean, functionsResponse: any, token: any } });

      return {
        success: false,
        message: 'errorWhileCheckin',
        displayDuration: 6000,
        displayType: CheckInResultDisplayType.Toast,
      };
    }
    this.context.commit('setLoading', false);
    return {
      success: true,
    };
  }

  @Action
  async checkOut () {
    this.context.commit('setLoading', true);

    await cloudFunctions.call('checkout', { token: localStorageHelper.loadCheckoutToken() });
    localStorageHelper.deleteCheckoutToken();

    await router.push({ name: RouteNames.CheckedOut });
    this.context.commit('setLoading', false);
  }

  @Action
  async reInitCheckedIn (options?: IReinitCheckinOptions) {
    if (this.pendingVisit && !options?.force) {
      return;
    }
    const checkoutToken = localStorageHelper.loadCheckoutToken();
    if (!checkoutToken) {
      await router.push({ name: RouteNames.NotFound });
      return;
    }

    if (!options || !options.silent) {
      this.context.commit('setLoading', true);
    }

    try {
      const pendingVisitsResponse = await cloudFunctions.call('loadPendingVisit', { token: checkoutToken });
      const result = pendingVisitsResponse.data?.result;
      if (result && !result.success) {
        if (result.reason === PendingVisitLoadingDenialReasons.AlreadyCheckedOut) {
          await this.context.dispatch('forceFrontendCheckout', options?.redirectUrl);
        }
        if (result.reason === PendingVisitLoadingDenialReasons.NoVisitFound) {
          await this.context.dispatch('forceFrontendCheckout', options?.redirectUrl);
        }
        if (result.reason === PendingVisitLoadingDenialReasons.VenueNotFound) {
          await this.context.dispatch('forceFrontendCheckout', options?.redirectUrl);
        }
        if (result.reason === PendingVisitLoadingDenialReasons.InvalidTokenId) {
          await this.context.dispatch('forceFrontendCheckout', options?.redirectUrl);
        }
        if (result.reason === PendingVisitLoadingDenialReasons.InvalidToken) {
          await this.context.dispatch('forceFrontendCheckout', options?.redirectUrl);
        }
        this.context.commit('setLoading', false);
        return;
      }
      this.context.commit('setPendingVisit', pendingVisitsResponse.data.result.data);

      // Reinit visitconditions to make sure logos and stuff are restored as well
      const visitConditionsStore = getModule(VisitConditionsStoreModule, store);
      visitConditionsStore.reInitVisitConditions(this.pendingVisit?.visitConditions);
    } catch (e) {
      return {
        success: false,
        message: e.message,
        displayDuration: 6000,
        displayType: CheckInResultDisplayType.Toast,
      };
    }
    this.context.commit('setLoading', false);
    return {
      success: true,
    };
  }

  @Action
  async forceFrontendCheckout (redirectPath?: string) {
    localStorageHelper.deleteCheckoutToken();
    if (redirectPath) {
      await router.push({ path: redirectPath });
      return;
    }
    await router.push({ name: RouteNames.CheckedOut });
  }
}
