










import Component from 'vue-class-component';
import Vue from 'vue';

@Component({
  name: 'VenueAreaLabel',
})
export default class VenueAreaLabel extends Vue {}
