








import Component from 'vue-class-component';
import Vue from 'vue';

@Component({
  name: 'Footer',
})
export default class Footer extends Vue {
  get venueId () {
    return this.$visitConditionsStore.visitConditions?.venueId;
  }

  get isUserVisit () {
    return this.$visitConditionsStore.visitConditions?.isUserVisit;
  }

  get dataProtectionLink () {
    return (this.isUserVisit)
      ? `https://einfachgast.de/datenschutzerklaerung-app-besucherregistrierung?egVenue=${this.venueId}`
      : `https://einfachgast.de/datenschutzerklaerung-app?egVenue=${this.venueId}`;
  }
}
