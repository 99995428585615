































































































































import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CheckInModes } from '@/enums/checkin-modes';
import { IVisit, IVisitConditions } from '@einfachgast/shared';
import CustomFieldsContainer from './custom-fields/custom-fields-container.vue';
import { ValidationHelpers } from '@/mixins/validation-helpers';
import FormField from './my-data-form/form-field.vue';

@Component({
  name: 'MyDataForm',
  components: {
    CustomFieldsContainer,
    FormField,
  },
})
export default class MyDataForm extends mixins(ValidationHelpers) {
  minuteTicker: number = null;

  @Prop()
  value!: IVisit;

  @Prop()
  visitConditions: IVisitConditions;

  get isAdminQrCode () {
    return this.visitConditions?.isAdminCode;
  }

  get isCheckinCheckoutMode () {
    return this.visitConditions?.checkinMode === CheckInModes.CheckInCheckOut;
  }

  get mustUpdateStartTime () {
    return this.visitConditions?.isAdminCode && this.visitConditions?.checkinMode === CheckInModes.Standard;
  }

  get mustShowAreaSelect () {
    return this.isAdminQrCode &&
      this.visitConditions?.areas?.length > 1;
  }

  get customFields () {
    return this.visitConditions?.customFields;
  }

  mounted () {
    this.setupMinuteTickerInterval();
  }

  beforeDestroy () {
    this.clearMinuteTickerInterval();
  }

  clearMinuteTickerInterval () {
    clearInterval(this.minuteTicker);
  }

  setupMinuteTickerInterval () {
    if (this.mustUpdateStartTime) {
      this.minuteTicker = window.setInterval(() => {
        this.value.start = new Date();
      }, 60000);
    }
  }
}
