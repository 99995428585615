



































































































import Component, { mixins } from 'vue-class-component';
import CompanionForm from '@/components/companions/companion-form.vue';
import EinfachGastLogo from '@/components/einfach-gast-logo.vue';
import MyDataForm from '@/components/my-data-form.vue';
import SafetyHints from '@/components/safety-hints.vue';
import { defaultCompanionFields } from '@/config/default-companion-fields';
import VenueLogo from '@/components/venue-logo.vue';
import VenueIntroText from '@/components/venue-intro-text.vue';
import VenueAreaLabel from '@/components/venue-area-label.vue';
import CheckinConfirmation from '@/components/checkin-confirmation.vue';
import Vue from 'vue';
import localStorageHelper from '@/helpers/local-storage-helper';
import FormField from '@/components/my-data-form/form-field.vue';
import { CheckinConfirmationSettingType, IVisit, IVisitConditions } from '@einfachgast/shared';
import { Visit } from '@/models/visit';
import { ValidationError } from '@/classes/validation-error';
import { Prop } from 'vue-property-decorator';
import { CheckInModes } from '@/enums/checkin-modes';
import { RouteNames } from '@/router';
import { CheckInResultDisplay } from '@/mixins/checkin-result-display';

type IVisitProps = Pick<IVisit, 'firstname' | 'lastname' | 'street' | 'city' | 'zipcode' | 'phone'>;

const validate = (
  visit: IVisit,
  visitConditions: IVisitConditions,
  validationGroups: { groups: string[] },
  vue: Vue,
  requiredCustomFieldNames: string[] = [],
): ValidationError[] => {
  const errors: ValidationError[] = [];

  // eslint-disable-next-line no-useless-escape
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const emailFieldSettings = vue.$visitConditionsStore.defaultFieldSettings.find(x => x.fieldName === 'email');
  const emailIsRequired = emailFieldSettings?.isRequired;
  const emailIsEnabled = emailFieldSettings?.enabled;
  // check email field when is enabled AND required
  if (emailIsEnabled && emailIsRequired && !emailRegex.test(visit.email)) {
    errors.push({
      target: visit,
      property: 'email',
      value: visit.email,
      constraints: {
        required: vue.$t('plsEnterAValidEmail').toString(),
      },
    });
  // check email field when is enabeld, no required but enter something
  } else if (emailIsEnabled && visit.email !== '' && !emailRegex.test(visit.email)) {
    errors.push({
      target: visit,
      property: 'email',
      value: visit.email,
      constraints: {
        required: vue.$t('plsEnterAValidEmail').toString(),
      },
    });
  }

  // Define fields that have a required min-length
  const minLengthFields: Array<{ key: keyof IVisitProps; minLength: number }> = [
    { key: 'firstname', minLength: 2 },
    { key: 'lastname', minLength: 2 },
    { key: 'street', minLength: 2 },
    { key: 'city', minLength: 2 },
    { key: 'zipcode', minLength: 4 },
    { key: 'phone', minLength: 4 },
  ];

  minLengthFields.forEach(f => {
    const fieldSettings = vue.$visitConditionsStore.defaultFieldSettings.find(x => x.fieldName === f.key);
    // continue when field is not required or disabled
    if (!fieldSettings?.isRequired || !fieldSettings.enabled) {
      return;
    }
    if (!visit[f.key] || visit[f.key].length < f.minLength) {
      errors.push({
        target: visit,
        property: f.key,
        value: visit[f.key],
        constraints: {
          'min-length': vue.$t('requiredFieldMindLength', { minLength: f.minLength }).toString(),
        },
      });
    }
  });

  // Required fields
  const requiredFields: { property: keyof Pick<IVisit, 'checkinConfirmationcheckboxChecked' | 'base64Signature'| 'areaId' | 'start'>, message?: string }[] = [
    { property: 'areaId' },
    { property: 'start' },
  ];

  if (visitConditions?.checkinConfirmationSettings.active) {
    if (visitConditions?.checkinConfirmationSettings.type === CheckinConfirmationSettingType.SignaturePad) {
      requiredFields.push({
        property: 'base64Signature',
        message: vue.$t('plsSign').toString(),
      });
    } else if (visitConditions?.checkinConfirmationSettings.type === CheckinConfirmationSettingType.CheckBox) {
      requiredFields.push({
        property: 'checkinConfirmationcheckboxChecked',
        message: vue.$t('pleaseConfirmConditions').toString(),
      });
    }
  }

  requiredFields.forEach(f => {
    if (!visit[f.property]) {
      errors.push({
        target: visit,
        property: f.property,
        value: visit[f.property],
        constraints: {
          required: `${f.message || vue.$t('requiredField')}.`,
        },
      });
    }
  });

  requiredCustomFieldNames.forEach(fieldname => {
    if (!visit.customFields[fieldname]) {
      errors.push({
        target: visit,
        property: fieldname,
        value: visit.customFields[fieldname],
        constraints: {
          required: `${vue.$t('requiredField')}.`,
        },
      });
    }
  });

  return errors;
};

@Component({
  name: 'VisitForm',
  components: {
    CompanionForm,
    FormField,
    MyDataForm,
    VenueLogo,
    EinfachGastLogo,
    SafetyHints,
    VenueIntroText,
    VenueAreaLabel,
    CheckinConfirmation,
  },
})
export default class VisitForm extends mixins(CheckInResultDisplay) {
  loading = false;
  errors: ValidationError[] = [];
  companionErrors: Array<ValidationError[]> = [];
  hasCompanions: boolean | null = null;
  visit: IVisit = new Visit();
  covidTestNotacceptedErrorMessage: string = null;
  safetyHintsNotAcceptedErrorMessage = '';
  safetyHintsAccepted = false;

  @Prop()
  qrId: string;

  get visitConditions () {
    return this.$visitConditionsStore.visitConditions;
  }

  get covidOptions () {
    return this.visitConditions?.covidOptions;
  }

  get covidOptionsShowNoteText () {
    return this.covidOptions?.showNoteText;
  }

  get covidOptionsShowNegativeCheckbox () {
    return this.covidOptions?.showNegativeCheckbox;
  }

  get dataProtectionLinkTranslated () {
    const dataProtectionLinkWithOpenTag = '<a href="' + ((this.visitConditions?.isUserVisit)
      ? `https://einfachgast.de/datenschutzerklaerung-app-besucherregistrierung?egVenue=${this.venueId}`
      : `https://einfachgast.de/datenschutzerklaerung-app?egVenue=${this.venueId}`) +
      '" target="_blank">';
    return this.$t(this. isCheckinCheckoutMode ? 'readAndApplyDataProtection' : 'readAndApplyDataProtectionVisit', {
      linkOpen: dataProtectionLinkWithOpenTag,
      linkClose: '</a>',
    });
  }

  get isCheckinCheckoutMode () {
    return this.visitConditions?.checkinMode === CheckInModes.CheckInCheckOut;
  }

  get isUserVisit () {
    return this.visitConditions?.isUserVisit;
  }

  get saveButtonText () {
    if (this.isCheckinCheckoutMode) {
      return this.$t('checkin');
    }
    return this.$t('save');
  }

  get isLoading () {
    return this.$visitConditionsStore?.loading ||
      this.$visit?.loading;
  }

  get validationGroups () {
    const validationGroups = ['default'];

    if (this.visitConditions?.isAdminCode) {
      validationGroups.push('adminCode');
    }

    return validationGroups;
  }

  get venueId () {
    return this.visitConditions?.venueId;
  }

  get hasErrors () {
    return this.errors.length > 0 ||
      this.companionErrors.length > 0 ||
      (this.covidOptionsShowNegativeCheckbox &&
        !this.visit.covidTestCheckboxChecked);
  }

  get displaySafetyHints () {
    return this.visitConditions?.displaySafetyHints &&
      this.visitConditions?.safetyInstructions && this.visitConditions?.safetyInstructions?.active;
  }

  get customFields () {
    return this.visitConditions?.customFields;
  }

  get requiredCustomFields () {
    return this.customFields?.filter(x => x.required);
  }

  get requiredCustomFieldNames () {
    return this.requiredCustomFields?.map(x => x.name);
  }

  get companionFields () {
    if (!this.visitConditions?.companionFields || this.visitConditions.companionFields.length <= 0) {
      return defaultCompanionFields;
    }
    return this.visitConditions.companionFields;
  }

  get requiredCompanionFields () {
    return this.companionFields.filter(x => x.required).map(x => x.name);
  }

  get safetyHintsOptional () {
    return this.visitConditions.safetyInstructions.required === false;
  }

  async mounted () {
    await this.initVisitConditionData();
  }

  async initVisitConditionData () {
    try {
      await this.$visitConditionsStore.initVisitConditions(this.qrId.trim());
      if (this.visitConditions.isAdminCode && this.visitConditions?.areas.length === 1) {
        /* Only set first area, when there is only one to prevent
        unwanted behavior of always selecting the first */
        this.visit.areaId = this.visitConditions?.areas[0].id;
      }
    } catch (e) {
      await this.$router.push({ name: RouteNames.NotFound });
    }
    this.visit.duration = this.visitConditions?.duration;
    this.visit.displayedCovidNote = this.covidOptionsShowNoteText;

    // If the safety hints have been displayed, we mark this in our model
    this.visit.displayedSafetyHints = this.displaySafetyHints;

    const existingData = localStorageHelper.loadVisitorData();
    if (!existingData || this.visitConditions?.isAdminCode) {
      return;
    }
    this.visit.initializeFromStorage(existingData);
  }

  async submit () {
    if (this.covidOptionsShowNegativeCheckbox && !this.visit.covidTestCheckboxChecked) {
      this.covidTestNotacceptedErrorMessage = this.$t('plsApplyCoronaConditions').toString();
      this.$buefy.toast.open({
        message: this.$t('plsApplyCoronaConditions').toString(),
        position: 'is-bottom',
        type: 'is-danger',
      });
    }

    // Safety features accepted? (when applicable)
    if (this.displaySafetyHints && !this.safetyHintsOptional && !this.visit.safetyHintsAccepted) {
      this.safetyHintsNotAcceptedErrorMessage = this.$t('safetyHintFormErrorMessage').toString();
      this.$buefy.toast.open({
        message: this.$t('safetyHintFormErrorMessage').toString(),
        position: 'is-bottom',
        type: 'is-danger',
      });
      return;
    }

    if (!this.isCheckinCheckoutMode) {
      this.visit.updateEndDate();
    }

    if (!this.visit.areaId && !this.visitConditions.isAdminCode) {
      this.visit.areaId = this.visitConditions?.areaId;
    }

    this.errors = validate(this.visit, this.visitConditions, { groups: this.validationGroups }, this, this.requiredCustomFieldNames);

    if (this.errors && this.errors.length > 0) {
      // if error = scroll auto to the visit form
      const visitForm = this.$el.querySelector('.visit-form');
      if (visitForm) {
        visitForm.scrollIntoView();
      }
    }

    this.companionErrors = this.validateCompanions();

    // If there are any errors the user must resolve them first
    if (this.hasErrors) {
      return;
    }

    // Proceed actual save or Checkin action
    let saveCheckInResult = null;
    if (this.isCheckinCheckoutMode) {
      saveCheckInResult = await this.$visit.checkIn({ visit: this.visit, asAdmin: this.visitConditions.isAdminCode });
    } else {
      saveCheckInResult = await this.$visit.save({ visit: this.visit, asAdmin: this.visitConditions.isAdminCode });
    }
    this.displayToastByCheckInResult(saveCheckInResult);

    if (this.visitConditions?.isAdminCode) {
      this.visit = new Visit();
      if (this.visitConditions?.areas.length === 1) {
        /* Only set first area, when there is only one to prevent
        unwanted behavior of always selecting the first */
        this.visit.areaId = this.visitConditions?.areas[0].id;
      }
      (this.$refs.myDataForm as MyDataForm).setupMinuteTickerInterval();
      await this.initVisitConditionData();
      window.scrollTo(0, 0);
    }
    this.clearSignaturePad();
    this.safetyHintsAccepted = false;
  }

  clearSignaturePad () {
    (this.$refs.checkinConfirmation as CheckinConfirmation)?.clearSignaturePad();
  }

  addCompanion () {
    this.companionErrors = this.validateCompanions();
    if (this.companionErrors && this.companionErrors.length > 0) {
      return;
    }

    const blankCompanion: Record<string, unknown> = {};
    this.companionFields.forEach(x => blankCompanion[x.name] = '');
    this.visit.companions.push(blankCompanion);
  }

  validateCompanions () {
    const errors: Array<ValidationError[]> = [];
    this.visit.companions.forEach((companion, index) => {
      const error = this.validateCompanion(companion);
      if (error && error.length > 0) {
        errors[index] = error;
      }
    });
    return errors;
  }

  validateCompanion (companion: Record<string, unknown> ) {
    const errors: ValidationError[] = [];
    this.requiredCompanionFields.forEach(fieldName => {
      if (!companion[fieldName] || (companion[fieldName] as string).length <= 0) {
        errors.push({
          target: companion,
          property: fieldName,
          value: companion[fieldName],
          constraints: {
            'required-field': this.$t('thisIsARequiredField').toString(),
          },
        });
      }
    });
    return errors;
  }

  removeCompanion (index: number) {
    this.visit.companions.splice(index, 1);
    this.companionErrors.splice(index, 1);
  }
}
