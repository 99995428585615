// mixins.js
import { ValidationError } from '@/classes/validation-error';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

// You can declare mixins as the same style as components.
@Component
export class ValidationHelpers extends Vue {
  @Prop({ default: (): ValidationError[] => [] })
  errors: ValidationError[];

  get hasErrors () {
    return this.errors &&
      this.errors.length > 0;
  }

  /**
   * Finds, and Merges all error Messages Regarding the given Fieldname
   * @param {string} fieldName the name of the field
   * @return {string} the messages
   */
  getFieldErrorsAsString (fieldName: string) {
    const fieldErrors = this.errors.filter(error => error.property === fieldName);
    if (!fieldErrors) {
      return;
    }
    return fieldErrors.map(x => Object.values((x.constraints || {})).map(t => this.$t(t))).join(',');
  }

  /**
   * Remove all entries from errors array, that are matching the given field name
   * @param {string} fieldName the name of the field we want to clear errors of
   */
  clearErrors (fieldName: string) {
    const index = this.errors.findIndex(x => x.property === fieldName);
    if (index === -1) {
      return;
    }
    this.errors.splice(index, 1);
  }

  /**
   * Retrieve field errors state by name
   * @param {string} fieldName the name of the field
   * @return {string} the bulma name for the current state
   */
  getFieldColor (fieldName: string) {
    const fieldErrors = this.getFieldErrorsAsString(fieldName);
    if (fieldErrors && fieldErrors) {
      return 'is-danger';
    }
    return '';
  }
}
