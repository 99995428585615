class ObfusciationHelper {
  /**
  * "Encrypts" given object to string
  */
  encrypt (data: unknown) {
    return btoa(JSON.stringify(data));
  }

  /**
   * "Decrypts" given string to object
   */
  decrypt (ciphertext: string) {
    return JSON.parse(atob(ciphertext));
  }

  hash (clear: string) {
    return this.encrypt(clear);
  }
}
const obfusciationHelper = new ObfusciationHelper();

export { obfusciationHelper };
