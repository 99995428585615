import { IVisit } from '@einfachgast/shared';
import { StorageData } from '@/models/storage-data';
import SimpleStorage from 'simplestorage';
import { obfusciationHelper } from './obfusciation-helper';

class LocalStorageHelper {
  protected simpleStorage: SimpleStorage = new SimpleStorage();

  /**
   * Get hashed Storage key. for persisted user data
   * @return {string} the key
   */
  get storageKey () {
    return obfusciationHelper.hash('noodle');
  }

  /**
   * Get hashed Storage key. for cookie acceptance
   * @return {string} the key
   */
  get cookieStorageKey () {
    return obfusciationHelper.hash('noodleCookie');
  }

  /**
   * Get hashed Storage key for checkout token
   * @return {string} the key
   */
  get checkoutTokenStorageKey () {
    return obfusciationHelper.hash('noodleCheckoutToken');
  }

  /**
   * Encrypt and strip to save as few data as possible.
   * @param visiorData {IVisit} the data to encrypt
   */
  prepareVisitorDataForSaving (visiorData: IVisit) {
    return new StorageData({ visitData: visiorData }).toEncryptedString();
  }

  /**
   * Save encrypted form data in local storage
   * @param data {IVisit} the raw Form Data
   */
  saveVisitorData (data: IVisit) {
    this.simpleStorage.set(this.storageKey, this.prepareVisitorDataForSaving(data));
  }

  /**
   * Save encrypted checkout token in local storage
   * @param token {string} the raw token
   */
  saveCheckoutToken (token: string) {
    this.simpleStorage.set(this.checkoutTokenStorageKey, btoa(token));
  }

  /**
   * delete stored checkout Token
   */
  deleteCheckoutToken () {
    this.simpleStorage.remove(this.checkoutTokenStorageKey);
  }

  /**
   * Save encrypted checkout token in local storage
   * @param token {string} the raw token
   */
  loadCheckoutToken () {
    const token = this.simpleStorage.get(this.checkoutTokenStorageKey);
    if (!token) {
      return null;
    }
    return atob(token);
  }

  /**
   * Load and decrypt data from Local storage
   * @return storageData {IStorageData} the data from storage
   */
  loadVisitorData () {
    const data = this.simpleStorage.get(this.storageKey);
    if (!data) {
      return;
    }
    return new StorageData({ storageData: data });
  }

  /**
   * Checks if the current user already has Accepted cookies
   */
  hasAcceptedCookies () {
    return this.simpleStorage.get(this.cookieStorageKey);
  }

  /**
   * Places an entry in local storage, that marks cookies as accepted
   */
  acceptCookies () {
    this.simpleStorage.set(this.cookieStorageKey, true);
  }
}

const localStorageHelper = new LocalStorageHelper();
export default localStorageHelper;
