import { ICompanionFieldDefinition, CustomInputType } from '@einfachgast/shared';

const defaultCompanionFields: ICompanionFieldDefinition[] = [
  {
    name: 'name',
    inputtype: CustomInputType.Text,
    required: true,
    label: {
      'de': 'Name',
      'en': 'Name',
    },
    order: 0,
    includeInCsvExport: true,
    showOnSuccessPage: true,
  },
  {
    name: 'phone',
    inputtype: CustomInputType.Text,
    required: true,
    label: {
      'de': 'Telefon',
      'en': 'Phone',
    },
    order: 20,
    includeInCsvExport: true,
    showOnSuccessPage: false,
  },
];

export { defaultCompanionFields };