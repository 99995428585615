




















import Component, { mixins } from 'vue-class-component';
import { ICustomFieldDefinition } from '@einfachgast/shared';
import { Prop } from 'vue-property-decorator';
import { ValidationHelpers } from '@/mixins/validation-helpers';

@Component({
  name: 'CustomCompanionField',
})
export default class CustomCompanionField extends mixins(ValidationHelpers) {
  @Prop()
  fieldData: ICustomFieldDefinition;

  @Prop()
  value: { [key: string]: string };

  get label () {
    let label = this.fieldData?.label[this.$i18n.locale];
    if (this.fieldData?.required) {
      label += '*';
    }
    return label;
  }
}
