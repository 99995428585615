
import { SentryEvent } from '@/classes/sentry/sentry-event';
import { SentryException, SentryStacktraceFrame } from '@/classes/sentry/sentry-exception';
// import { extractExceptionKeysForMessage, isEvent, normalizeToSize } from '@sentry/utils';

import { computeStackTrace, IStackFrame as TraceKitStackFrame, IStackTrace as TraceKitStackTrace } from '@/helpers/stacktrace-helper';

const STACKTRACE_LIMIT = 50;

/**
 * @hidden
 */
export function prepareFramesForEvent (stack: TraceKitStackFrame[]): SentryStacktraceFrame[] {
  if (!stack || !stack.length) {
    return [];
  }

  let localStack = stack;

  const firstFrameFunction = localStack[0].func || '';
  const lastFrameFunction = localStack[localStack.length - 1].func || '';

  // If stack starts with one of our API calls, remove it (starts, meaning it's the top of the stack - aka last call)
  if (firstFrameFunction.indexOf('captureMessage') !== -1 || firstFrameFunction.indexOf('captureException') !== -1) {
    localStack = localStack.slice(1);
  }

  // If stack ends with one of our internal API calls, remove it (ends, meaning it's the bottom of the stack - aka top-most call)
  if (lastFrameFunction.indexOf('sentryWrapped') !== -1) {
    localStack = localStack.slice(0, -1);
  }

  // The frame where the crash happened, should be the last entry in the array
  return localStack
    .slice(0, STACKTRACE_LIMIT)
    .map(
      (frame: TraceKitStackFrame): SentryStacktraceFrame => ({
        colno: frame.column === null ? undefined : frame.column,
        filename: frame.url || localStack[0].url,
        function: frame.func || '?',
        in_app: true,
        lineno: frame.line === null ? undefined : frame.line,
      }),
    )
    .reverse();
}

/**
 * This function creates an exception from an TraceKitStackTrace
 * @param stacktrace TraceKitStackTrace that will be converted to an exception
 * @hidden
 */
export function exceptionFromStacktrace (stacktrace: TraceKitStackTrace): SentryException {
  const frames = prepareFramesForEvent(stacktrace.stack);

  const exception: SentryException = new SentryException(
    stacktrace.name,
    stacktrace.message,
    '',
  );

  if (frames && frames.length) {
    exception.stacktrace = { frames };
  }

  if (exception.type === undefined && exception.value === '') {
    exception.value = 'Unrecoverable error caught';
  }

  return exception;
}

/**
 * @hidden
 */
export function eventFromPlainObject (
  exception: Record<string, unknown>,
  syntheticException?: Error,
  rejection?: boolean,
): SentryEvent {
  const event: SentryEvent = new SentryEvent();
  event.exception = {
    values: [new SentryException(
      /* isEvent(exception) ? exception.constructor.name : rejection ? 'UnhandledRejection' : */'Error',
          `Non-Error ${
            rejection ? 'promise rejection' : 'exception'
          } captured with keys: `, // ${extractExceptionKeysForMessage(exception)}`,
          ''),
    ],
  };

  if (syntheticException) {
    const stacktrace = computeStackTrace(syntheticException);
    prepareFramesForEvent(stacktrace.stack);
  }

  return event;
}

/**
 * @hidden
 */
export function eventFromStacktrace (stacktrace: TraceKitStackTrace): SentryEvent {
  const exception = exceptionFromStacktrace(stacktrace);

  const sentryEvent = new SentryEvent();
  sentryEvent.exception = {
    values: [exception],
  };
  return sentryEvent;
}
