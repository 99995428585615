import { SentryEvent } from '@/classes/sentry/sentry-event';
import { ISentryHelper } from '@/interfaces/i-sentry-helper';
import Axios from 'axios';
import { SentryUriHelper } from './sentry-uri-helper';
import { VueEnvHelper } from '../vue-envvar-helper';

export class SentryService implements ISentryHelper {
  sentryUriHelper: SentryUriHelper
  sentryDsn: string

  private envHelper: VueEnvHelper;

  constructor () {
    this.envHelper = new VueEnvHelper();
    this.sentryDsn = this.envHelper.get('SENTRY_DSN');
    if (this.sentryDsn) {
      this.sentryUriHelper = new SentryUriHelper(this.sentryDsn);
    }
  }

  get Environment () {
    return (this.envHelper.get('ENVIRONMENT') || process.env.NODE_ENV).toLowerCase();
  }

  capture (sentryEvent: SentryEvent) {
    sentryEvent.release = this.envHelper.get('GIT_SHA');
    sentryEvent.environment = this.Environment;
    return Axios.post(this.sentryUriHelper.Url, sentryEvent);
  }

  captureError (error: Error) {
    return this.capture(SentryEvent.fromError(error));
  }

  captureDefaultErrorHandler (err: Error, vm: Vue, info: string) {
    // no sentry dsn => no tracking active (develop)
    if (this.sentryDsn) {
      return this.captureError(err);
    }
  }
}
