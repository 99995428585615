import { SentryException } from './sentry-exception';
import { SentryRequest } from './sentry-request';
import { v4 as uuidv4 } from 'uuid';
import { computeStackTrace } from '@/helpers/stacktrace-helper';
import { eventFromStacktrace } from '@/helpers/sentry/parsers';

export class SentryEvent {
  event_id: string
  culprit: string
  timestamp: string
  message: string
  tags: string[]
  exception: { values: SentryException[] } = { values: [] }
  request: SentryRequest
  release: string
  environment: string

  public static fromError (error: Error): SentryEvent {
    const sentryEvent = eventFromStacktrace(computeStackTrace(error));
    sentryEvent.event_id = uuidv4();
    return sentryEvent;
  }
}
