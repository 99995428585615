


























import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { ICompanionFieldDefinition } from '@einfachgast/shared';
import { ValidationError } from '@/classes/validation-error';
import CustomFieldsContainer from '../custom-fields/custom-fields-container.vue';

@Component({
  name: 'CompanionForm',
  components: {
    CustomFieldsContainer,
  },
})
export default class CompanionForm extends Vue {
  @Prop()
  value!: Record<string, unknown>;

  @Prop()
  index!: number;

  @Prop()
  fields!: ICompanionFieldDefinition[];

  @Prop({ default: () => ([] as ValidationError[]) })
  errors!: ValidationError[];

  remove () {
    this.$emit('remove', this.index);
  }

  clear () {
    Object.keys(this.value).forEach(x => {
      this.value[x] = '';
    });
  }
}
