import { obfusciationHelper } from '@/helpers/obfusciation-helper';
import { IVisit, IStorageData } from '@einfachgast/shared';

export class StorageData implements IStorageData {
  public firstname = '';
  public lastname = '';
  public street = '';
  public zipcode = '';
  public city = '';
  public phone = '';
  public email = '';
  public customFields? = {};

  constructor (options?: { visitData?: IVisit; storageData?: string }) {
    if (!options) {
      return;
    }

    let initialData: IVisit | IStorageData;

    if (options.storageData) {
      initialData = obfusciationHelper.decrypt(options.storageData);
    } else if (options.visitData) {
      initialData = options.visitData;
    } else {
      return;
    }
    this.firstname = initialData.firstname;
    this.lastname = initialData.lastname;
    this.street = initialData.street;
    this.zipcode = initialData.zipcode;
    this.city = initialData.city;
    this.phone = initialData.phone;
    this.email = initialData.email;
    this.customFields = initialData.customFields;
  }

  toEncryptedString () {
    return obfusciationHelper.encrypt(Object.assign({}, this));
  }
}
