export default {
  myData: 'My data',
  enterCompanions: 'Enter companions',
  readAndApplyDataProtection: 'I have read the {linkOpen}privacy policy{linkClose} and agree with it.',
  successRegisterGuest: '<strong>{name}</strong> was successfully registered.',
  successRegisterGuestCheckIn: '<strong>{name}</strong> was successfully checked in.<br /><br /> <strong>Please contact the staff when you wish to check out again.</strong>',
  applyOfNegativeCoronaTest: '<strong>3G – </strong> I confirm that I have a valid negative corona test or that I have complete vaccination protection or that I have recovered.',
  requiredFields: 'Required fields',
  thisIsARequiredField: 'This field is required',
  minutes: 'minutes',
  requiredField: 'Required field',
  noteOfCovid19: 'I assure that I / we do not suffer from symptoms typical of Covid19 (especially fever, cough and shortness of breath, taste and smell disorders), that I / we have not been in contact with a verifiably infected person, that I / we are in the have not stayed in an area classified as a risk area by the Robert Koch Institute in the last two weeks and that I / we have not been quarantined either.',
  plsEnterAValidEmail: 'Please enter a valid email address.',
  requiredFieldMindLength: 'Required field. Please enter at least {minLength} characters.',
  checkin: 'Check in',
  save: 'Save',
  plsApplyDatatProtection: 'Please agree to the privacy policy',
  plsApplyCoronaConditions: 'Please confirm the corona requirements',
  cannotLoadQrCode: 'QR code cannot be loaded',
  plsCheckTheUrl: 'Please check the url.',
  onMoreProblemsContactEmployee: 'If you continue to have problems, please contact our staff',
  successfulRegister: 'Registered successfully!',
  clockUntil: 'to',
  clock: '',
  escorts: 'Companions',
  companions: 'Companions',
  companion: 'Companion',
  remove: 'remove',
  plsLetOpenThePageForServicePersonal: 'Please leave this page open to confirm the data collection to our service personnel.',
  moreGuestsRegister: 'Enter more guests',
  watchNow: 'Further Information',
  letYourFriendsKnowYouAreHere: 'Share Your Visit or give us Feedback!',
  cookieNote: 'Our website uses cookies. Only cookies that are necessary for operation are stored.',
  iAgree: 'I agree',
  checkedOutSuccessfully: 'Checked out successfully!',
  thanksForYourVisit: 'Thanks for your visit!',
  checkedInSuccessfully: 'Checked in successfully!',
  pleaseNoteTheMaximumLengthOfStay: 'Please note the maximum length of stay of <b> {maxDurationStay} minutes </b> in our premises. After this time, you will be checked out automatically. Thank you for your understanding.',
  lengthOfStay: 'Length of stay',
  checkOut: 'Check out',
  phone: 'Phone',
  name: 'Name',
  imprint: 'Imprint',
  dataProtection: 'Data protection',
  arrivalTime: 'Arrival time',
  selectArrivalTime: 'Select arrival time',
  area: 'Area',
  pleaseSelect: 'Please select',
  estimatedLengthOfStay: 'Estimated length of stay',
  firstname: 'Firstname',
  lastname: 'Lastname',
  streetHouseNr: 'Street / No.',
  zipCode: 'Zip code',
  city: 'City',
  Email: 'Email',
  visitorLimitReachedError: 'The maximum number of visitors has currently been reached. Please try again later.',
  errorWhileSaving: 'Error while saving. Please check your data and contact our service staff if necessary.',
  errorWhileCheckin: 'Check-in error. Please check your data and contact our service staff if necessary.',
  reallyWantToCheckout: 'Do you really want to checkout?',
  noCancel: 'No, cancel',
  yesCheckout: 'Yes, checkout',
  signatureRequired: 'Please use the signature pad to sign',
  plsSign: 'Please sign here',
  yourSignature: 'Please sign here',
  pleaseConfirmConditions: 'Please confirm the conditions displayed',

  // Safety Hints
  safetyHints: 'Safety Hints',
  safetyHintsIntroduction: 'Your safety is more than an obligation to us, but one of our highest goals. For your own safety and the safety of others, please adhere to our environment, safety and hygiene regulations throughout our premises.',
  safetyHintWearVisitorBadge: 'Please wear the identification pass clearly visible during your stay.',
  safetyHintProtectiveClothing: 'ESD protective clothing applies in production. In addition, no one may enter the production and storage area without authorization and without the assistance of a member of staff.',
  safetyHintForklift: 'Pay attention to forklifts throughout the company premises.',
  safetyHintSmoking: 'Smoking is only allowed in specially designated outdoor areas.',
  safetyHintSpeedLimit: 'Unless stated otherwise, the provisions of the Road Traffic Regulations as well as a general speed limit of 10 km/h apply throughout the entire plant, parking lots included.',
  safetyHintPhotography: 'Photographing and filming are prohibited. You should refrain from touching technical parts, products and materials without permission.',
  safetyHintEmergencyInstructor: 'In case of an emergency, please follow your guide\'s instructions. Use the escape routes and emergency exits, and gather at the marked muster station',
  safetyHintEmergencyBehavior: 'In case of emergency: Keep calm. Help caring for accident victims and with the necessary tasks and notifications',
  safetyHintsCheckboxLabel: 'I have read the safety hints above and agree to them.',
  safetyHintFormErrorMessage: 'Please confirm that you have read the safety hints.',

  // Language switch
  deLang: 'Deutsch',
  enLang: 'English',
  esLang: 'Español',
  frLang: 'Français',
  ruLang: 'русский',
  trLang: 'Türk',
};
