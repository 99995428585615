class CookieHelper {
  cookies: { [key: string]: string } = {}

  constructor () {
    document.cookie.split(';').forEach(cookie => {
      const [name, value] = cookie.trim().split('=');
      this.cookies[name] = value;
    });
  }

  has (name: string) {
    return Object.keys(this.cookies).findIndex(key => key === name) !== -1;
  }

  get (name: string): string {
    const val = this.cookies[name];

    const falsy = ['', '0', 'false', 'off', 'no'];
    if (falsy.indexOf(val) !== -1) {
      return '';
    }

    return val;
  }

  set (name: string, value: string | number | boolean, samesite = 'strict', path?: string, maxAge?: number, expires?: Date) {
    let cookie = `${name}=${value}; samesite=${samesite};`;

    if (path?.length > 0) {
      cookie = `${cookie} path=${path};`;
    }

    if (maxAge?.toString().length > 0) {
      cookie = `${cookie} max-age=${maxAge};`;
    }

    if (expires && Object.prototype.toString.call(expires) === '[object Date]' && !isNaN(expires.getTime())) {
      cookie = `${cookie} expires=${expires.toTimeString()};`;
    }

    document.cookie = cookie;
  }
}

const cookieHelper = new CookieHelper();

export default cookieHelper;
