export class SentryException {
  constructor (type: string, value: string, module: string) {
    this.type = type;
    this.value = value;
    this.module = module;
  }

  type: string
  value: string
  module: string
  stacktrace: SentryStacktrace
}

export class SentryStacktrace {
  frames: SentryStacktraceFrame[]
}

export class SentryStacktraceFrame {
  colno: number
  filename: string
  function: string
  in_app: boolean
  lineno: number
}
