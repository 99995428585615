






































































































import Component, { mixins } from 'vue-class-component';
import VenueLogo from '@/components/venue-logo.vue';
import EinfachGastLogo from '@/components/einfach-gast-logo.vue';
import CompanionsList from '@/components/companions/companions-list.vue';
import { Prop } from 'vue-property-decorator';
import { IArea } from '@/interfaces/i-area';
import { IVisit } from '@einfachgast/shared';
import { IReinitCheckinOptions } from '@/interfaces/i-reinit-checkin-options';
import { ToastProgrammatic as Toast, DialogProgrammatic as Dialog } from 'buefy';
import { CheckInResultDisplay } from '@/mixins/checkin-result-display';
import { defaultCompanionFields } from '@/config/default-companion-fields';

@Component({
  name: 'CheckedIn',
  components: {
    VenueLogo,
    EinfachGastLogo,
    CompanionsList,
  },
})
export default class CheckedIn extends mixins(CheckInResultDisplay) {
  loading = true;
  failedAttempt = false;
  checkoutToken: string = null;
  visit: IVisit = null;
  secondsElapsed: number = null;
  counterInterval: number = null;
  companionTableColumns: { field: string; label: string }[] = [{ field: 'name', label: 'Name' }];
  updateInterval: number = null;

  @Prop()
  redirectUrl: string;

  get mustShowEinfachgastLogo() {
    return this.visitConditions && !this.visitConditions.hideEinfachGastLogo;
  }

  get scanAppFinishText () {
    return this.visitConditions?.scanAppFinishText?.[this.$i18n.locale];
  }

  get isLoading () {
    return this.$visit.loading;
  }

  get cityString () {
    if (!this.pendingVisit) {
      return '';
    }
    return `${this.pendingVisit.zipcode} ${this.pendingVisit.city}`;
  }

  get companions () {
    if (!this.pendingVisit) {
      return [];
    }
    return this.pendingVisit.companions;
  }

  get hasCustomCompanionsForm () {
    return (this.visitConditions?.companionFields || []).length > 0;
  }

  get companionFields () {
    if (!this.visitConditions?.companionFields || this.visitConditions.companionFields.length <= 0) {
      return defaultCompanionFields;
    }
    return this.visitConditions.companionFields;
  }

  get hasCompanions () {
    return this.pendingVisit &&
      this.pendingVisit.companions &&
      this.pendingVisit.companions.length > 0;
  }

  get visitConditions () {
    return this.$visitConditionsStore.visitConditions;
  }

  get maxDurationStay () {
    return this.visitConditions?.maxStayDuration;
  }

  get displayName () {
    if (!this.pendingVisit) {
      return '';
    }
    return `${this.pendingVisit.firstname} ${this.pendingVisit.lastname}`;
  }

  get canDisplaySocialLinks () {
    return this.isFacebookLink || this.isGoogleFeedbackLink;
  }

  get checkinTime () {
    if (!this.pendingVisit || !this.pendingVisit.startDate) {
      return new Date();
    }
    // Fallback: When startDate contains a Firebase.Timestamp for whatever reason, use that
    if (Object.keys(this.pendingVisit.startDate as unknown as { _seconds: number }).indexOf('_seconds') !== -1) {
      return new Date((this.pendingVisit.startDate as unknown as { _seconds: number })._seconds * 1000);
    }
    return new Date(this.pendingVisit.startDate);
  }

  get runningTime () {
    // format milliseconds to seconds and create minutes, hours, days from sec
    // s = seconds; m = minutes; h = hours; d = days
    let s = Math.floor((this.secondsElapsed / 1000));
    let m = Math.floor(s / 60);
    let h = Math.floor(m / 60);
    const d = Math.floor(h / 24);
    h = h - (d * 24);
    m = m - (d * 24 * 60) - (h * 60);
    s = s - (d * 24 * 60 * 60) - (h * 60 * 60) - (m * 60);

    if (d >= 1) {
      return `${d} T. ${h.toString().padStart(2, '0')} Std.`;
    } else if (h >= 1) {
      return `${h} Std. ${m.toString().padStart(2, '0')} Min.`;
    } else {
      return `${m} Min. ${s.toString().padStart(2, '0')} Sek.`;
    }
  }

  get isFacebookLink () {
    return this.visitConditions?.facebookLink !== '';
  }

  get isGoogleFeedbackLink () {
    return this.visitConditions?.googleFeedbackLink !== '';
  }

  get canDisplayMenuBtn () {
    if (!this.visitConditions) {
      return false;
    }
    if (!this.visitConditions.menuName || !this.visitConditions.menuUrl) {
      return false;
    }
    return true;
  }

  get areaLabel () {
    if (!this.visitConditions) {
      return '';
    }
    if (!this.visitConditions.areas) {
      return '';
    }
    const area = this.visitConditions.areas.find((x: IArea) => x.id === this.pendingVisit.visitConditions.areaId);
    return area.name;
  }

  get pendingVisit () {
    return this.$visit.pendingVisit;
  }

  async mounted () {
    if (!this.pendingVisit) {
      await this.init({ force: false, silent: false, redirectUrl: this.redirectUrl });
    }
    this.resetUpdateInterval();

    this.secondsElapsed = this.getElapsedSeconds();
    this.counterInterval = window.setInterval(() => {
      this.secondsElapsed = this.getElapsedSeconds();
    }, 1000);
  }

  destroyed () {
    clearInterval(this.counterInterval);
    this.clearUpdateInterval();
  }

  async init (options: IReinitCheckinOptions = { force: true, silent: true, redirectUrl: this.redirectUrl }) {
    const checkInResult = await this.$visit.reInitCheckedIn(options);
    this.displayToastByCheckInResult(checkInResult);
    this.resetUpdateInterval();
  }

  setupUpdateInterval () {
    this.updateInterval = window.setInterval(() => {
      if (document.hasFocus()) {
        void this.init();
      }
    }, 40000);
  }

  clearUpdateInterval () {
    clearInterval(this.updateInterval);
  }

  resetUpdateInterval () {
    this.clearUpdateInterval();
    this.setupUpdateInterval();
  }

  getElapsedSeconds () {
    const cTime = this.checkinTime?.getTime();
    if (!cTime) {
      return 0;
    }
    return new Date().getTime() - cTime;
  }

  openMenuUrl () {
    window.open(this.visitConditions.menuUrl, '_blank');
  }

  openFacebookUrl () {
    window.open(this.visitConditions.facebookLink);
  }

  openGoogleUrl () {
    window.open(this.visitConditions.googleFeedbackLink);
  }

  visibilityChange (evt: Event, hidden: boolean) {
    if (!hidden) { // only trigger onFocus
      void this.init({ silent: true, force: true });
    }
  }

  checkOut () {
    Dialog.confirm({
      message: this.$t('reallyWantToCheckout').toString(),
      cancelText: this.$t('noCancel').toString(),
      confirmText: this.$t('yesCheckout').toString(),
      onConfirm: async () => {
        try {
          await this.$visit.checkOut();
        } catch (err) {
          Toast.open({
            message: this.$t('errorWhileCheckout').toString(),
            position: 'is-bottom',
            type: 'is-danger',
            queue: false,
          });
        }
      },
    });
  }
}
