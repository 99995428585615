




































import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { ISafetyInstructions } from '@einfachgast/shared';

@Component({
  name: 'SafetyHints',
})
export default class SafetyHints extends Vue {
  @Prop()
  error: boolean;

  @Prop()
  errorMsg: string;

  @Prop()
  value: boolean;

  @Prop()
  safteyInstructions: ISafetyInstructions;

  get visitConditions () {
    return this.$visitConditionsStore.visitConditions;
  }

  get safetyHintsOptional () {
    return this.visitConditions.safetyInstructions.required === false;
  }

  get defaultLang () {
    return this.visitConditions?.settings?.defaultLanguage;
  }

  get instructionItems () {
    return this.safteyInstructions.instructionItems.sort((a, b) => a.position - b.position);
  }

  get noteText () {
    return this.safteyInstructions.introText[this.$i18n.locale]
      ? this.safteyInstructions.introText[this.$i18n.locale]
      : this.safteyInstructions.introText[this.defaultLang];
  }

  getItemText (textItem: {[key: string]: string}) {
    const text = textItem[this.$i18n.locale]
      ? textItem[this.$i18n.locale]
      : textItem[this.defaultLang];
      // @see: https://stackoverflow.com/a/44124/9757222
      return text.replace(/<(?!\/?a(?=>|\s.*>))\/?.*?>/g, '');
  }
}
