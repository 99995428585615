





















import Component, { mixins } from 'vue-class-component';
import { ValidationHelpers } from '@/mixins/validation-helpers';
import { IVisit } from '@einfachgast/shared';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'FormField',
})
export default class FormField extends mixins(ValidationHelpers) {
  @Prop()
  value!: IVisit;

  @Prop()
  fieldName!: keyof IVisit;

  @Prop()
  translateKey: string;

  @Prop()
  type: string;

  @Prop()
  inputmode: string;

  get fieldSettings () {
    return this.$visitConditionsStore.defaultFieldSettings.find(x => x.fieldName === this.fieldName);
  }

  get isEnabled () {
    return this.fieldSettings?.enabled;
  }

  get label () {
    return `${this.$t(this.translateKey)}${(this.fieldSettings.isRequired) ? '*' : ''}`;
  }
}
