






import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import SvgIcon from '@jamescoyle/vue-icon';

import {
  mdiAccount,
  mdiAccountSupervisor,
  mdiAlertCircle,
  mdiCalendar,
  mdiCheckCircleOutline,
  mdiClipboardFileOutline,
  mdiClockOutline,
  mdiFacebook,
  mdiInstagram,
  mdiLeadPencil,
  mdiMapMarker,
  mdiMinus,
  mdiPlus,
  mdiDelete,
  mdiChevronDown,
} from '@mdi/js';

/**
 * This is a compatibility wrapper component to integrate Vue(Svg)Icon with Bulma/Buefy
 */
@Component({
  name: 'BuefyIconRenderer',
  components: {
    SvgIcon,
  },
})
export default class BuefyIconRenderer extends Vue {
  @Prop()
  icon: string[];

  @Prop({ default: 0 })
  rotate: number;

  @Prop()
  size: string;

  @Prop()
  customClass: string;

  get path () {
    const ic = this.icon.pop();
    switch (ic) {
      case 'mdi-account': return mdiAccount;
      case 'mdi-account-supervisor': return mdiAccountSupervisor;
      case 'mdi-alert-circle': return mdiAlertCircle;
      case 'mdi-calendar': return mdiCalendar;
      case 'mdi-check-circle-outline': return mdiCheckCircleOutline;
      case 'mdi-clipboard-file-outline': return mdiClipboardFileOutline;
      case 'mdi-facebook': return mdiFacebook;
      case 'mdi-instagram': return mdiInstagram;
      case 'mdi-map-marker': return mdiMapMarker;
      case 'mdi-minus': return mdiMinus;
      case 'mdi-clock-outline': return mdiClockOutline;
      case 'mdi-plus': return mdiPlus;
      case 'mdi-lead-pencil': return mdiLeadPencil;
      case 'mdi-chevron-down': return mdiChevronDown;
      case 'mdi-delete': return mdiDelete;
    }

    throw new Error(`Missing import for icon "${ic}"`);
  }

  get computedSize () {
    switch (this.size) {
      case 'mdi-24px': case 'is-small': return 24;
      case 'mdi-36px': case 'is-medium': return 36;
      case 'mdi-48px': case 'is-large': return 48;
    }
    return 28;
  }

  get computedFlip () {
    const cssClasses = this.$parent.$props.customClass;
    if (!cssClasses) return '';

    if (cssClasses.indexOf('flip-h') !== -1 && cssClasses.indexOf('flip-v') !== -1) {
      return 'both';
    }
    if (cssClasses.indexOf('flip-h') !== -1) {
      return 'horizontal';
    }
    if (cssClasses.indexOf('flip-v') !== -1) {
      return 'vertical';
    }
    return '';
  }
}
