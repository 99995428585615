








import Component from 'vue-class-component';
import EinfachGastLogo from '@/components/einfach-gast-logo.vue';
import Vue from 'vue';
@Component({
  name: 'SplashScreen',
  components: {
    EinfachGastLogo,
  },
})
export default class SplashScreen extends Vue {}
