import { IFormField } from '@einfachgast/shared';

const defaultFieldSettings: IFormField[] = [
  {
    fieldName: 'firstname',
    enabled: true,
    isRequired: true,
  },
  {
    fieldName: 'lastname',
    enabled: true,
    isRequired: true,
  },
  {
    fieldName: 'street',
    enabled: true,
    isRequired: true,
  },
  {
    fieldName: 'city',
    enabled: true,
    isRequired: true,
  },
  {
    fieldName: 'zipcode',
    enabled: true,
    isRequired: true,
  },
  {
    fieldName: 'phone',
    enabled: true,
    isRequired: true,
  },
  {
    fieldName: 'email',
    enabled: true,
    isRequired: false,
  },
  {
    fieldName: 'base64Signature',
    enabled: true,
    isRequired: false,
  },
  {
    fieldName: 'checkinConfirmationcheckboxChecked',
    enabled: true,
    isRequired: false,
  },
];

export function getMergedDefaultFieldSettings (fieldSettings: IFormField[]) {
  return defaultFieldSettings.map(x => {
    const catchedFieldSettings = fieldSettings.find(fs => fs.fieldName === x.fieldName);
    if (catchedFieldSettings) {
      return Object.assign(x, catchedFieldSettings);
    }
    return x;
  });
}
