













import Component from 'vue-class-component';
import DeveloperHint from './components/developer-hint.vue';
import LanguageSwitch from './components/language-switch.vue';
import Footer from './components/footer.vue';
import Vue from 'vue';

@Component({
  name: 'App',
  components: {
    DeveloperHint,
    LanguageSwitch,
    Footer,
  },
})
export default class App extends Vue {}
