import { IVisitStore } from '@/interfaces/i-visit-store';
import { store } from '@/store';
import { VisitStoreModule } from '@/store/visit-store-module';
import _Vue from 'vue'; // <-- notice the changed import
import { getModule } from 'vuex-module-decorators';

// export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;
export function VisitsPlugin (Vue: typeof _Vue): void {
  Vue.prototype.$visit = getModule(VisitStoreModule, store);
}

declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Vue {
    $visit: IVisitStore;
  }
}
