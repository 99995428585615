/**
 * This Helper transforms sentry dsn urls (https://9c6001f158494d969c30022bde5b1eb7@o508637.ingest.sentry.io/5602950)
 * to urls where you can post events (https://o508637.ingest.sentry.io/api/5603381/store/?sentry_key=f19c18261e1741a18d0b8c0cdbe92381&sentry_version=7)
 * SentryDSN format is: {PROTOCOL}://{PUBLIC_KEY}:{SECRET_KEY}@{HOST}{PATH}/{PROJECT_ID}
 */
export class SentryUriHelper {
  url: URL;

  constructor (dsn: string) {
    this.url = new URL(dsn);
  }

  get Url () {
    return `${this.Protocol}//${this.Host}/api/${this.ProjectId}/store/?sentry_key=${this.Public}&sentry_version=7`;
  }

  get Protocol () {
    return this.url.protocol;
  }

  get Host () {
    return this.url.host;
  }

  get ProjectId () {
    return this.url.pathname.split('/').filter(x => x).shift();
  }

  get Public () {
    return this.url.username;
  }
}
