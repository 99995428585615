












































import Component from 'vue-class-component';
import Vue from 'vue';
import FormField from '@/components/my-data-form/form-field.vue';
import { CheckinConfirmationSettingType, IVisit } from '@einfachgast/shared';
import { ValidationError } from '@/classes/validation-error';
import { Prop } from 'vue-property-decorator';
import VueSignaturePad from 'vue-signature-pad';

@Component({
  name: 'CheckinConfirmation',
  components: {
    FormField,
  },
})
export default class CheckinConfirmation extends Vue {
  @Prop()
  errors: ValidationError[];

  @Prop()
  visit: IVisit;

  get visitConditions () {
    return this.$visitConditionsStore.visitConditions;
  }

  get needsConfirmationSignaturePad () {
    return this.visitConditions?.checkinConfirmationSettings?.active
      && this.visitConditions?.checkinConfirmationSettings.type === CheckinConfirmationSettingType.SignaturePad;
  }

  get needsConfirmationCheckbox () {
    return this.visitConditions?.checkinConfirmationSettings?.active
      && this.visitConditions?.checkinConfirmationSettings.type === CheckinConfirmationSettingType.CheckBox;
  }

  get defaultLang () {
    return this.visitConditions?.settings?.defaultLanguage;
  }

  get checkinConfirmationLabel () {
    return this.checkinConfirmationLabelLanguages[this.$i18n.locale]
      ? this.checkinConfirmationLabelLanguages[this.$i18n.locale]
      : this.checkinConfirmationLabelLanguages[this.defaultLang];
  }

  get checkinConfirmationLabelLanguages () {
    return this.visitConditions?.checkinConfirmationSettings?.label[this.visitConditions?.checkinConfirmationSettings.type];
  }

  get hasSignatureError () {
    return this.errors.some(x => x.property === 'base64Signature');
  }

  onEnd () {
    const result = (this.$refs.signaturePad as VueSignaturePad).saveSignature();
    this.visit.base64Signature = result.data;
    this.clearError('base64Signature');
  }

  clearError (property: string) {
    const hasErrorIndex = this.errors.findIndex(x => x.property === property);
    if (hasErrorIndex !== -1) {
      this.errors.splice(hasErrorIndex, 1);
    }
  }

  clearSignaturePad() {
    (this.$refs.signaturePad as VueSignaturePad)?.clearSignature();
    this.visit.base64Signature = '';
  }
}
