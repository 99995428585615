
















import Component from 'vue-class-component';
import { CustomFieldPositon, ICompanionFieldDefinition, ICustomFieldDefinition, IVisit } from '@einfachgast/shared';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import CustomField from './custom-field.vue';
import CustomCompanionField from './custom-companion-field.vue';
import { ValidationError } from '@/classes/validation-error';
@Component({
  name: 'CustomFieldsContainer',
  components: {
    CustomField,
    CustomCompanionField,
  },
})
export default class CustomFieldsContainer extends Vue {
  @Prop({ default: (): ValidationError[] => [] })
  errors: ValidationError[];

  @Prop({ default: (): ICustomFieldDefinition[] => [] })
  customFields: ICustomFieldDefinition[] | ICompanionFieldDefinition[];

  @Prop()
  position: CustomFieldPositon;

  @Prop({ default: 'CustomField' })
  fieldRenderer: string;

  @Prop()
  value: IVisit;

  get fields () {
    let customFields = [...(this.customFields || [])];
    if (this.position) {
      customFields = (customFields as unknown as ICustomFieldDefinition[])
        ?.filter(x => x.position === this.position);
    }
    return customFields.sort((a, b) => a.order - b.order);
  }

  get hasFields () {
    return this.fields.length > 0;
  }
}
