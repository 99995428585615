








import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { ICompanionFieldDefinition } from '@einfachgast/shared';

@Component({
  name: 'CompanionListItem',
})
export default class CompanionListItem extends Vue {
  @Prop()
  companion!: Record<string, unknown>;

  @Prop()
  companionFields: ICompanionFieldDefinition[];

  get visitConditions () {
    return this.$visitConditionsStore.visitConditions;
  }

  get displayString () {
    return (this.companionFields || [])
      .filter(field => field.showOnSuccessPage)
      .map(field => this.companion[field.name])
      .join(' ')
      .trimRight();
  }

}
