




























































































































import Component from 'vue-class-component';
import Vue from 'vue';
import VenueLogo from '@/components/venue-logo.vue';
import EinfachGastLogo from '@/components/einfach-gast-logo.vue';
import sessionStorageHelper from '@/helpers/session-storage-helper';
import { Visit } from '@/models/visit';
import { IVisit } from '@einfachgast/shared';
import { Prop } from 'vue-property-decorator';
import CompanionsList from '@/components/companions/companions-list.vue';
import { defaultCompanionFields } from '@/config/default-companion-fields';

@Component({
  name: 'Finished',
  components: {
    VenueLogo,
    EinfachGastLogo,
    CompanionsList,
  },
})
export default class Finished extends Vue {
  visit: IVisit = new Visit();
  loading = false;

  @Prop()
  qrId: string;

  get companions () {
    if (!this.visit) {
      return [];
    }
    return this.visit.companions;
  }

  get hasCustomCompanionsForm () {
    return (this.visitConditions?.companionFields || []).length > 0;
  }

  get companionFields () {
    if (!this.visitConditions?.companionFields || this.visitConditions.companionFields.length <= 0) {
      return defaultCompanionFields;
    }
    return this.visitConditions.companionFields;
  }

  get hasCompanions () {
    return this.visit &&
      this.visit.companions &&
      this.visit.companions.length > 0;
  }

  get scanAppFinishText () {
    return this.visitConditions?.scanAppFinishText?.[this.$i18n.locale] || this.$t('plsLetOpenThePageForServicePersonal');
  }

  get visitConditions () {
    return this.$visitConditionsStore.visitConditions;
  }

  get isAdminCode () {
    return this.$visitConditionsStore.visitConditions?.isAdminCode;
  }

  get startDate () {
    if (!this.visit) {
      return '';
    }
    return this.visit.start?.toLocaleDateString();
  }

  get startTime () {
    if (!this.visit) {
      return '';
    }
    return this.visit.start?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }

  get endTime () {
    if (!this.visit) {
      return '?';
    }
    if (!this.visit.end) {
      return '?';
    }
    return new Date(this.visit.end)?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }

  get canDisplaySocialLinks () {
    return this.isFacebookLink || this.isGoogleFeedbackLink;
  }

  get isFacebookLink () {
    return this.visitConditions?.facebookLink !== '';
  }

  get isGoogleFeedbackLink () {
    return this.visitConditions?.googleFeedbackLink !== '';
  }

  get canDisplayMenuBtn () {
    return this.visitConditions?.menuName && this.visitConditions?.menuUrl &&
      this.visitConditions?.menuName !== '' && this.visitConditions?.menuUrl !== '';
  }

  get visitorInfos () {
    if (!this.visit) {
      return '';
    }
    return `${this.visit.firstname} ${this.visit.lastname}, ${this.visit.zipcode} ${this.visit.city}`;
  }

  get areaLabel () {
    if (!this.visitConditions || !this.visit) {
      return '';
    }
    if (!this.visitConditions.areas) {
      return '';
    }
    const area = this.visitConditions.areas.find(x => x.id === this.visit.areaId);
    return area?.name;
  }

  async created () {
    try {
      await this.$visitConditionsStore.initVisitConditions(this.qrId.trim());
      const visitData = sessionStorageHelper.loadVisitorData();
      if (!visitData) {
        await this.$router.push({ name: '404' });
      }
      this.visit = new Visit(visitData);
    } catch (e) {
      await this.$router.push({ name: '404' });
    }

    this.loading = false;
  }

  async newCheckin () {
    await this.$router.push({ path: `/qr/${this.qrId.trim()}` });
  }

  openMenuUrl () {
    window.open(this.visitConditions.menuUrl, '_blank');
  }

  openFacebookUrl () {
    window.open(this.visitConditions.facebookLink);
  }

  openGoogleUrl () {
    window.open(this.visitConditions.googleFeedbackLink);
  }
}
