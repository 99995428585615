export default {
  myData: 'Meine Daten',
  enterCompanions: 'Begleitperson eintragen',
  readAndApplyDataProtection: 'Mit dem Checkin stimme ich der {linkOpen}Datenschutzerklärung{linkClose} zu.',
  readAndApplyDataProtectionVisit: 'Mit der Anmeldung stimme ich der {linkOpen}Datenschutzerklärung{linkClose} zu.',
  successRegisterGuest: '<strong>{name}</strong> wurde erfolgreich eingetragen.',
  successRegisterGuestCheckIn: '<strong>{name}</strong> wurde erfolgreich eingecheckt.<br /><br /> <strong>Bitte wenden Sie sich an das Personal, wenn Sie wieder auschecken möchten.</strong>',
  applyOfNegativeCoronaTest: '<strong>3G – </strong> Ich bestätige, dass ich einen gültigen negativen Corona-Test besitze oder einen vollständigen Impfschutz aufweise oder als genesen gelte.',
  requiredFields: 'Pflichtfelder',
  minutes: 'Minuten',
  requiredField: 'Pflichtfeld',
  thisIsARequiredField: 'Dies ist ein Pflichtfeld',
  noteOfCovid19: 'Ich versichere, dass ich/wir nicht an Covid19-typischen Krankheitssymptomen (v.a. Fieber, Husten und Atemnot, Geschmacks- und Geruchsstörungen) leide(n), nicht im Kontakt mit einer nachweislich infizierten Person stand(en), mich/wir uns in den letzten zwei Wochen nicht in einem vom Robert-Koch-Institut als Risikogebiet eingestuften Gebiet aufgehalten habe(n) und, dass ich/wir auch nicht unter Quarantäne gestellt bin/sind.',
  plsEnterAValidEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse an.',
  requiredFieldMindLength: 'Pflichtfeld. Bitte geben Sie mindestens {minLength} Zeichen ein.',
  checkin: 'Einchecken',
  save: 'Speichern',
  plsApplyDatatProtection: 'Bitte akzeptieren Sie die Datenschutzbestimmungen',
  plsApplyCoronaConditions: 'Bitte bestätigen Sie die Corona-Auflagen',
  cannotLoadQrCode: 'QR-Code kann nicht geladen werden',
  plsCheckTheUrl: 'Bitte überprüfen Sie die URL.',
  onMoreProblemsContactEmployee: 'Falls weiterhin Probleme auftreten, wenden Sie sich bitte an unsere Mitarbeiter',
  successfulRegister: 'Erfolgreich eingetragen!',
  clockUntil: 'Uhr bis',
  clock: 'Uhr',
  escorts: 'Begleitpersonen',
  companions: 'Begleiter',
  companion: 'Begleiter',
  remove: 'Entfernen',
  plsLetOpenThePageForServicePersonal: 'Bitte lassen Sie diese Seite noch geöffnet, um unserem Servicepersonal die Datenerfassung zu bestätigen.',
  moreGuestsRegister: 'Weiteren Gast eintragen',
  watchNow: 'Weitere Informationen',
  letYourFriendsKnowYouAreHere: 'Teilen Sie Ihren Besuch oder geben Sie uns Feedback!',
  cookieNote: 'Unsere Webseite verwendet Cookies. Es werden nur Cookies gespeichert, die für den Betrieb notwendig sind.',
  iAgree: 'Einverstanden',
  checkedOutSuccessfully: 'Erfolgreich ausgecheckt!',
  thanksForYourVisit: 'Vielen Dank für Ihren Besuch!',
  checkedInSuccessfully: 'Erfolgreich eingecheckt!',
  pleaseNoteTheMaximumLengthOfStay: 'Bitte beachten Sie die maximale Aufenthaltsdauer von <b>{maxDurationStay} Minuten</b> in unseren Räumlichkeiten. Nach Ablauf dieser Zeit, werden Sie automatisch ausgecheckt. Vielen Dank für Ihr Verständnis.',
  lengthOfStay: 'Aufenthaltsdauer',
  checkOut: 'Auschecken',
  phone: 'Telefon',
  name: 'Name',
  imprint: 'Impressum',
  dataProtection: 'Datenschutzerklärung',
  arrivalTime: 'Ankunftszeit',
  selectArrivalTime: 'Ankunftszeit auswählen',
  area: 'Bereich',
  pleaseSelect: 'Bitte wählen',
  estimatedLengthOfStay: 'Voraussichtliche Aufenthaltsdauer',
  firstname: 'Vorname',
  lastname: 'Nachname',
  streetHouseNr: 'Straße / Hausnummer',
  zipCode: 'Postleitzahl',
  city: 'Stadt',
  Email: 'E-Mail',
  visitorLimitReachedError: 'Aktuell ist die Höchstzahl von Besuchern erreicht. Bitte probieren Sie es zu einem späteren Zeitpunkt erneut.',
  errorWhileSaving: 'Fehler beim Speichern. Bitte prüfen Sie Ihre Daten und wenden sich ggf. an unsere Servicekräfte.',
  errorWhileCheckin: 'Fehler beim Einchecken. Bitte prüfen Sie Ihre Daten und wenden sich ggf. an unsere Servicekräfte.',
  errorWhileCheckout: 'Fehler beim Auschecken. Bitte prüfen Sie Ihre Daten und wenden sich ggf. an unsere Servicekräfte.',
  reallyWantToCheckout: 'Möchten Sie wirklich auschecken?',
  noCancel: 'Nein, abbrechen',
  yesCheckout: 'Ja, auschecken',
  signatureRequired: 'Bitte nutzen Sie das Unterschriftsfeld um Ihre Unterschrift zu hinterlegen.',
  plsSign: 'Bitte unterschreiben Sie hier',
  yourSignature: 'Bitte hier unterschreiben',
  pleaseConfirmConditions: 'Bitte bestätigen Sie die angezeigten Bedingungen',

  // Safety Hints
  safetyHints: 'Sicherheitshinweise',
  safetyHintsIntroduction: 'Ihre Sicherheit ist für uns mehr als eine Verpflichtung, vielmehr eines unserer obersten Ziele. Bitte beachten Sie zu Ihrem eigenen Schutz im gesamten Werk unsere Umwelt-, Sicherheits- und Hygienevorschriften.',
  safetyHintWearVisitorBadge: 'Bitte tragen Sie Ihren Besucherausweis gut sichtbar während Ihres Aufenthaltes.',
  safetyHintProtectiveClothing: 'In der Fertigung gilt ESD-Schutzkleidung. Außerdem darf keiner unbefugt und ohne in Begleitung eines Betriebsangehörigen in die Fertigung und in den Lagerbereich.',
  safetyHintForklift: 'Achten Sie auf Gabelstapler im gesamten Betriebsgelände.',
  safetyHintSmoking: 'Rauchen ist nur in extra ausgewiesenen Plätzen im Außenbereich erlaubt.',
  safetyHintSpeedLimit: 'Auf dem Werksgelände - inklusive der Parkplätze - gilt, wenn nicht anders gekennzeichnet, Schrittgeschwindigkeit und die Straßenverkehrsordnung.',
  safetyHintPhotography: 'Das Fotografieren und Filmen ist untersagt. Das unaufgeforderte Berühren und Mitnehmen von technischen Teilen, Produkten und Materialien ist zu unterlassen.',
  safetyHintEmergencyInstructor: 'Im Notfall folgen Sie den Instruktionen Ihrer Begleitung. Benutzen Sie die Fluchtwege und Notausgänge und versammeln sich an den gekennzeichneten Stellen.',
  safetyHintEmergencyBehavior: 'Wichtig in Notfallsituationen: Bewahren Sie Ruhe. Unterstützen Sie Ihren Begleiter bei der Betreuung von Unfallopfern und den notwendigen Aufgaben/Meldungen.',
  safetyHintsCheckboxLabel: 'Ich habe die oben stehenden Sicherheitshinweise gelesen und akzeptiere diese.',
  safetyHintFormErrorMessage: 'Bitte bestätigen Sie, dass Sie die oben stehenden Sicherheitshinweise gelesen haben.',

  // Language switch
  deLang: 'Deutsch',
  enLang: 'English',
  esLang: 'Español',
  frLang: 'Français',
  ruLang: 'русский',
  trLang: 'Türk',
};
