import { IVisit } from '@einfachgast/shared';
import SimpleStorage from 'simplestorage';
import { obfusciationHelper } from './obfusciation-helper';

// @ToDo: all That crypto shit can simply be replaced by using the secure-ls module

class SessionStorageHelper {
  protected simpleStorage: SimpleStorage = new SimpleStorage({ storageType: 'SessionStorage' });

  /**
   * We use a hashed cookie name to gain a tiny security improvement by obfuscation
   * basically, it just feels better to have some wild string instead of clear application
   * name...
   * @return {string} the hashed cookieName
   */
  get cookieName (): string {
    return obfusciationHelper.hash('noodle');
  }

  /**
   * We use a hashed cookie name to gain a tiny security improvement by obfuscation
   * basically, it just feels better to have some wild string instead of clear application
   * name...
   * @return {string} the hashed cookieName
   */
  get tokenName (): string {
    return obfusciationHelper.hash('noodleVenueToken');
  }

  /**
   * Save Visit Data to use it on finished vie later on
   * @param data {IVisit} the visit data we want so save
   */
  saveVisitorData (data: IVisit): void {
    this.simpleStorage.set(this.cookieName, obfusciationHelper.encrypt(data));
  }

  /**
   * Retrieve visit data from session storage
   * @return {IVisit} the visit data
   */
  loadVisitorData (): IVisit {
    const visitorData = this.simpleStorage.get(this.cookieName);
    if (!visitorData) {
      return;
    }
    const plainData = obfusciationHelper.decrypt(visitorData);

    // Convert string date to real Date to satisfy IVisit interface
    plainData.start = new Date(plainData.start);
    plainData.end = new Date(plainData.end);

    return plainData;
  }

  saveVenueToken (token: string) {
    this.simpleStorage.set(this.tokenName, token);
  }

  loadVenueToken () {
    return this.simpleStorage.get(this.tokenName);
  }
}

const sessionStorageHelper = new SessionStorageHelper();
export default sessionStorageHelper;
