
















import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Vue from 'vue';
import CompanionListItem from '@/components/companions/companion-list-item.vue';
import { ICompanionFieldDefinition } from '@einfachgast/shared';

@Component({
  name: 'CompanionsList',
  components: {
    CompanionListItem,
  },
})
export default class CompanionsList extends Vue {
  @Prop()
  companions!: Record<string, unknown>[];

  @Prop()
  companionFields: ICompanionFieldDefinition[];

  get visitConditions () {
    return this.$visitConditionsStore.visitConditions;
  }

  get hasDisplayableFields () {
    return (this.companionFields || [])
      .filter(x => x.showOnSuccessPage)
      .length > 0;
  }

}
