

































import Component from 'vue-class-component';
import Vue from 'vue';
import VenueLogo from '@/components/venue-logo.vue';
import EinfachGastLogo from '@/components/einfach-gast-logo.vue';

@Component({
  name: 'CheckedOut',
  components: {
    VenueLogo,
    EinfachGastLogo,
  },
})
export default class CheckedOut extends Vue {
  get visitConditions() {
    return this.$visitConditionsStore.visitConditions;
  }

  get mustShowEGLogo() {
    return this.visitConditions && !this.visitConditions.hideEinfachGastLogo;
  }
}
